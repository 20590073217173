import { TablePagination } from "@mui/material";
import { useState } from "react";
import { ArrowUp, ArrowDown } from "lucide-react";

const Table = ({ 
  columns, 
  rows, 
  noNeedPagination = false,
  rowsPerPage: externalRowsPerPage = 25,
  page: externalPage = 0,
  handleChangePage: externalHandleChangePage,
  handleChangeRowsPerPage: externalHandleRowsPerPage,
  isRowsPerPage,
  sortByColumn = {}, 
  setSortByColumn 
}) => {
  // Internal state for pagination if not provided externally
  const [internalPage, setInternalPage] = useState(0);
  const [internalRowsPerPage, setInternalRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);

  // Use either external or internal pagination values
  const page = externalHandleChangePage ? externalPage : internalPage;
  const rowsPerPage = externalHandleRowsPerPage ? externalRowsPerPage : internalRowsPerPage;

  // Calculate pagination
  const indexOfLastRow = (page + 1) * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentItems = rows.slice(indexOfFirstRow, indexOfLastRow);
  const count = rows.length;

  // Handlers for internal pagination
  const handlePageChange = (event, newPage) => {
    if (externalHandleChangePage) {
      externalHandleChangePage(event, newPage);
    } else {
      setInternalPage(newPage);
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (externalHandleRowsPerPage) {
      externalHandleRowsPerPage(event);
    } else {
      setInternalRowsPerPage(newRowsPerPage);
      setInternalPage(0);
    }
  };

  const handleSort = (columnId) => {
    if (setSortByColumn) {
      setSortByColumn((prevSort) => ({
        column: columnId,
        order: prevSort.column === columnId && prevSort.order === "ASC" ? "DESC" : "ASC"
      }));
    }
  };

  // Simple loading placeholder
  const LoadingPlaceholder = () => (
    <div className="animate-pulse bg-gray-200 h-4 w-full rounded"></div>
  );

  return (
    <div className="w-full">
      <div className="rounded-lg border bg-white shadow">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column, index) => (
                  <th
                    key={index}
                    onClick={() => handleSort(column.id)}
                    className="px-4 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer"
                  >
                    <div className="flex items-center gap-2">
                      {/* Handle both string and object names */}
                      <span>{typeof column.name === 'object' ? column.name.props?.children || '' : column.name}</span>
                      {sortByColumn.column === column.id && (
                        sortByColumn.order === "ASC" ? <ArrowUp className="h-4 w-4" /> : <ArrowDown className="h-4 w-4" />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                // Loading state
                Array.from({ length: rowsPerPage }).map((_, rowIndex) => (
                  <tr key={`loading-${rowIndex}`}>
                    {columns.map((_, colIndex) => (
                      <td key={`loading-cell-${colIndex}`} className="">
                        <LoadingPlaceholder />
                      </td>
                    ))}
                  </tr>
                ))
              ) : rows.length > 0 ? (
                currentItems.map((row, rowIndex) => (
                  <tr 
                    key={rowIndex}
                    className="border-t hover:bg-gray-50"
                  >
                    {columns.map((column, colIndex) => (
                      <td 
                        key={colIndex}
                        className=" py-3 text-left  text-sm text-gray-500"
                      >
                        {column.cell(row)}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td 
                    colSpan={columns.length}
                    className="px-4 py-3 text-sm text-center text-gray-500"
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {!noNeedPagination && (
          <div className="border-t">
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={!isRowsPerPage ? [25, 50, 75, 100] : []}
              className="text-sm"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;